*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: #f4f0e4;
  position: relative;
  z-index: 1;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

li {
  list-style: none;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.container-md {
  max-width: 100rem;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .container {
    padding: 0 3rem;
  }

  .container-md {
    padding: 0 3rem;
  }
}

/* Pagination */
.pagination {
  padding: 3rem 0 5rem 0;
  text-align: center;
}

.pagination span {
  display: inline-block;
  padding: 1rem 1.5rem;
  border: 1px solid #243a6f;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.pagination span:hover {
  border: 1px solid #243a6f;
  background-color: #243a6f;
  color: #fff;
}
/* end pagination */

/* home  */
.home-container {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.home-image>img {
  max-width: 100%;
  object-fit: cover;
  height: 100%;
}

.home-content {
  padding: 30px 60px 30px 30px;
}

.home-about {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 1.7rem;
  font-weight: 400;
  color: #332f29 !important;
}

.home-title {
  margin: 10px 0 0;
  font-size: 6.5rem;
  color: #332f29 !important;
}

.home-context {
  font-size: 1.8rem;
  margin: 30px 0 0;
  color: #555c66 !important;
  line-height: 3rem;
}

.home-made {
  margin: 20px 0 0;
  color: #332f29 !important;
}

.home-btn {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
  margin: 30px auto 0 0;
  padding: 14px 52px 14px 51px;
  background-color: #fbddb0;
  border: none;
  cursor: pointer;
}
.home-btn:hover {
  background-color: #f9cc87;
}

@media only screen and (max-width: 768px) {
  .home-container {
    grid-template-columns: 1fr;
    grid-auto-flow: dense
  }

  .home-content {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

@media only screen and (max-width: 991px) {
  .home-title {
    font-size: 5.5rem;
  }
}
/* end home */
