
.message-no {
  margin: 15rem auto 15rem auto !important;
}

.notify {
  font-size: 2.5rem;
    padding: 2rem 0;
    text-align: center;
}

.notify.warning {
  background-color: #f8d7da;
}

.notify.warning >span {
  color: #aa5c63;
}