/* All Products */
.section .top {
  margin-bottom: 4rem;
  margin-top: 4rem;
}
/* animals */
.product-center {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 7rem 3rem;
}

.card-head {
  position: relative;
  height: 265px;
}

.animal-img {
  width: 100%;
  height: 100%;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.animal-detail h2 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.animal-detail p {
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 2px;
  font-style: italic;
}

.card-body {
  height: 250px;
  background: #fff;
  border-radius: 0 0 15px 15px;
  border: 1px solid white;
}

.animal-title h1 {
  padding: 20px 20px 5px 20px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge {
  position: relative;
  font-size: 10px;
  font-weight: 300;
  color: #fff;
  background: #243a6f;
  padding: 2px 5px;
  border-radius: 4px;
  top: -2px;
  margin-left: 5px;
}

.animal-caption {
  display: block;
  padding: 0 20px;
  font-style: italic;
}

.animal-info h4 {
  padding: 0 21px;
  margin-top: 150x;
  padding-bottom: 5px;
}

.animal-info>ul {
  font-size: 12px;
  padding: 0 30px;
}

.labels {
  font-weight: 700;
  text-transform: uppercase;
  color: #404040;
}

.animal-discription h4 {
  padding: 0 21px;
  margin-top: 10px;
  padding-bottom: 5px;
}

.animal-discription p {
  font-size: 12px;
  padding: 0 28px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.animal-load {
  display: block;
  margin: 0 auto 10rem auto;
}